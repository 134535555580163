import React from 'react';
import { Helmet } from "react-helmet";
import TemplateWrapper from '../components/layout';

const Prajs = () => (
<TemplateWrapper>
<div className="article-content">
<Helmet>
  <title>Прайс лист - hair.moscow</title>
  <meta name="description" content="Полный прайс услуг на наращивание и окрашивание волос в Москве" />
  <meta name="keywords" content="Прайс лист, наращивание, стоимость, цены, прайс, прайсинг, pricing" />
</Helmet>

  <h1 className="hm-title-1">Стоимость наращивания</h1>
            <small>Стоимость работы мастера не включает в себя стоимость волос</small>
            
  <div className="row">
  <div className="col-md-4">
	  
	  <h2 className="hm-pink">Наращивание капсульное </h2>
      <ul className="hm-list">
          <li>Стандарт 1 прядь - <b>50 ₽</b>
            <br /><small>цена до 150 прядей</small>
          </li>
             <li>Микро 1 прядь - <b>40 ₽</b>
            <br /><small>цена до 150 прядей</small>
          </li>
           <li>после 150 прядей,цена за 1 прядь - <b>30 ₽</b>
           <br /><small>микро/стандарт</small>
           </li>
      </ul>
	  
    </div>  
  <div className="col-md-4">
	  
	  <h2 className="hm-pink">Холодное ленточное - стандартные ленты (4 см)</h2>
      <ul className="hm-list">
          <li>Наращивание 1 объема (40 лент) <b>4000 ₽</b>
            <br /><small>1 лента - <b>100 ₽</b></small>
          </li>
          <li>Коррекция 1 объема (40 лент) - снятие, переклейка, наращивание <b>6000 ₽</b>
          <br /><small>1 лента - <b>150 ₽</b></small>
          </li>
          <li>Снятие 1 объема (40 лент) <b>1000 ₽</b>
          <br /><small>1 лента - <b>25 ₽</b></small>
          </li>
          <li>Переклейка скотча 1 объем (40 лент) <b>1000 ₽</b>
          <br /><small>1 лента - <b>25 ₽</b></small>
          </li>
		<li>Устранение спутанности, колтунов <b>1000-3000 ₽</b></li>
      </ul>
	  
    </div>  
	
	 <div className="col-md-4">
	 <h2 className="hm-pink">Холодное ленточное - микроленты (1-2 см)</h2>
	   <ul className="hm-list">
          <li>Наращивание 1 объема (80 лент) <b>5600 ₽</b>
            <br /><small>1 лента - <b>70 ₽</b></small></li>
          
          <li>Коррекция 1 объема (80 лент) - снятие, переклейка, наращивание <b>8400 ₽</b>
          <br /><small>1 лента - <b>105 ₽</b></small>
          </li>
          <li>Снятие 1 объема (80 лент) <b>1600 ₽</b>
          <br /><small>1 лента - <b>20 ₽</b></small>
          </li>
          <li>Переклейка скотча 1 объем (80 лент) <b>1200 ₽</b>
          <br /><small>1 лента - <b>15 ₽</b></small>
          </li>
		<li>Устранение спутанности, колтунов <b>1000-3000 ₽</b></li>
      </ul>
	 </div>
	
   <div className="col-md-4">
      <h2 className="hm-pink">Ботокс для волос</h2>
      <ul className="hm-list">
        <li>Короткие/средние <b>4000 ₽</b></li>
        <li>Длинные <b>5000 ₽</b></li>
        <li>Длинные наращенные <b>6000 ₽</b> </li>
        <li>Совместно с окрашиванием <b>3500 ₽</b> </li>
      </ul>
    </div>
    <div className="col-md-4">
      <h2 className="hm-pink">Голливудское (Афронаращивание)</h2>
      <ul className="hm-list">
        <li>Наращивание 1 объема тресса <b>8000 ₽</b></li>
        <li>Снятие тресса 1 объем <b>2000 ₽</b></li>
      </ul>
    </div>
  </div>

  <h1 className="hm-title-1">Стоимость окрашивания</h1>
  
  <div className="row">
      <div className="col-md-4">
      <h2 className="hm-pink">Окрашивание</h2>
      <ul className="hm-list">
          <li>Окрашивание корней <b>3000 ₽</b></li>
        <li>Осветление корней пудрой <b>1500 ₽</b></li>
      </ul>
      <h3 className="hm-pink">Окрашивание в тон</h3>
      <ul className="hm-list">
        <li>На короткие-средние <b>8000 ₽</b></li>
        <li>На длиные <b>10000 ₽</b></li>
		<li>Очень густые / наращенные <b>12000₽</b></li>
      </ul>
      <h3 className="hm-pink">Тонирование волос</h3>
      <ul className="hm-list">
        <li>На короткие-средние <b>3000 ₽ - 4000₽</b></li>
          <li>На длиные <b>5000 ₽</b></li>
          <li>Очень густые / наращенные <b>6000₽</b></li>
      </ul>
      <h3 className="hm-pink">Сложное окрашивание</h3>
	  <small>Шатуш, балаяж, растяжка цвета, пряди, мелирование, исправление некачественного окрашивания</small>
      <ul className="hm-list">
        <li>На короткие-средние <b>8000₽ - 9000₽</b></li>
        <li>На длиные <b>10000 ₽</b></li>
		<li>Очень густые / наращенные <b>11000₽</b></li>
      </ul>
    </div><div className="col-md-4">
      <h3 className="hm-pink">  Смывка, осветление пудрой</h3>
      <ul className="hm-list">
        <li>На короткие <b>2000 ₽</b></li>
        <li>На средние <b>3000 ₽</b></li>
        <li>На длиные <b>4000 ₽</b></li>
      </ul>
      {/* <small>Цена на первичное осветление без тонирования, Olaplex 1 доза входит в процедуру</small> */}
    </div>
  </div>

  <h1 className="hm-title-1">Дополнительные услуги</h1>
  <div className="row">
<div className="col-md-6">            
  <h3 className="hm-pink">Стрижки</h3>
  <ul className="hm-list">
    <li>Стрижка челки <b>500 ₽</b></li>
    <li>Стрижка кончиков (одним срезом) <b>500 ₽</b></li>
  </ul>
  <h3 className="hm-pink">Женские стрижки</h3>
  <ul className="hm-list">
    <li>На короткие <b>2000 ₽</b></li>
    <li>На средние <b>3000 ₽</b></li>
    <li>На длиные <b>4000 ₽</b></li>
  </ul>

</div><div className="col-md-6">  

  <h3 className="hm-pink">Укладки</h3>
  <ul className="hm-list">
    <li>Легкая укладка (без мытья головы) <b>1000 ₽</b></li>
    <li>Полная укладка (короткие волосы) <b>1000 ₽</b></li>
    <li>Полная укладка (средние волосы) <b>1500 ₽</b></li>
    <li>Полная укладка (длинные волосы) <b>2000 ₽</b></li>
  </ul>

  <h3 className="hm-pink">Прически</h3>
  <ul className="hm-list">
    <li>Объемные локоны,пучки,сложные прически <b>1500 - 5000 ₽</b></li>
  </ul>
    </div></div>

    <h1 className="hm-title-1">Фитоламинирование Liquias от Lebel</h1>
  <div className="row">
  <div className="col-md-12">
      <ul className="hm-list">
        <li>На короткие <b>3500 ₽</b></li>
        <li>На средние <b>4500 ₽</b></li>
        <li>На длинные <b>5500 ₽</b></li>
      </ul>
    </div>  
  </div>

  {/* <h1 className="hm-title-1">Уход Olaplex-spa</h1>
  <div className="row">
  <div className="col-md-12">
      <ul className="hm-list">
        <li>На короткие <b>4000 ₽</b></li>
        <li>На средние <b>5000 ₽</b></li>
        <li>На длинные <b>6000 ₽</b></li>
        <li>Olaplex (1 доза) <b>2000 ₽</b></li>
      </ul>
    </div>  
  </div> */}

  {/*<h1 className="hm-title-1">Brow bar</h1>
  <div className="row">
  <div className="col-md-12">
      <ul className="hm-list">
        <li>Коррекция воском <b>1000 ₽</b></li>
        <li>Био-окрашивание бровей хной <b>1000 ₽</b></li>
        <li>Окрашивание бровей краской <b>500 ₽</b></li>
        <li>Ламинирование ресниц <b>4000 ₽</b></li>
        <li>Удаление пушка над верхней губой <b>500 ₽</b></li>
        <li>Ламинирование ресниц + коррекция + окрашивание бровей хной <b>5000 ₽</b></li>

      </ul>
    </div>  
  </div> */}

</div>
</TemplateWrapper>
)

export default Prajs